import React from 'react';

import 'devextreme/dist/css/dx.common.css';

const enMessages = require('loc/DevExtreme-en.json');
const svMessages = require('loc/DevExtreme-sv.json');
import { locale, loadMessages } from 'devextreme/localization';

import { DataGrid, Export } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';

import { parseJSON } from 'shared/parse';
import { Template } from 'devextreme-react/core/template';
import { exportDataGrid } from 'devextreme/excel_exporter';

import 'devextreme-react/tag-box';
import './ActionListComponent.module.scss';

loadMessages(enMessages);
loadMessages(svMessages);
let dataGridLocale = 'en';
let currentThemeName = '';

interface IColumn {
  caption: string;
  dataField: string;
}

interface IListStrings {
  EditItem: string;
  DeleteItem: string;
  ItemCountTemplate: string;
}

export const ActionListComponent = React.forwardRef((props: {
  items: any[];
  columns: IColumn[];
  readOnly: boolean;
  shortUiLanguage: string;
  storageKeyName: string;
  strings: IListStrings;
  hostKind: string;
  isPlanEditEnabled: boolean;
  isEditorDisabled: (dataField: string, data: any) => boolean;

  onUpdateItemInplace: (newData: any, oldData: any) => Promise<any>;
  onUpdateItemForm: (keys: string[]) => void;

  onRemoveItemInplace: (key: string) => Promise<any>;
  onRemoveItemForm: (keys: string[]) => void;

  onRenderLinkField: (data) => JSX.Element;
  onExporting: (buffer) => void;
  themeName: string;
}, ref) => {

  const isMobile = (props.hostKind === 'TeamsMobile' || props.hostKind === 'WebMobile');
  const isWeb = (props.hostKind === 'WebMobile' || props.hostKind === 'WebDesktop');

  const currentLocale = props.shortUiLanguage;
  if (currentLocale !== dataGridLocale) {
    locale(currentLocale);
    dataGridLocale = currentLocale;
  }

  if (currentThemeName !== props.themeName) {
    switch (props.themeName) {
      case 'dark':
        require('devextreme/dist/css/dx.dark.css');
        break;
      case 'contrast':
        require('devextreme/dist/css/dx.contrast.css');
        break;
      default:
        require('devextreme/dist/css/dx.light.css');
        break;
    }
    currentThemeName = props.themeName;
  }

  const selectedKeys = React.useRef<string[]>([]);

  const onRowRemoving = (e) => {
    if (props.onRemoveItemInplace) {
      e.cancel = new Promise((resolve, reject) => {
        props.onRemoveItemInplace(e.key).then(() => {
          resolve(false);
        }, err => {
          reject(err);
        });
      });
    }
  };

  const onRowUpdating = (e) => {
    if (props.onUpdateItemInplace) {
      e.cancel = new Promise((resolve, reject) => {
        props.onUpdateItemInplace(e.newData, e.oldData).then(() => {
          resolve(false);
        }, err => {
          reject(err);
        });
      });
    }
  };

  const gridRef = React.useRef(null);

  React.useImperativeHandle(ref, () => ({
    resetView: () => {
      gridRef.current.instance.clearFilter();
      gridRef.current.instance.state(null);
    }
  }));

  const onSelectionChanged = (items: { selectedRowKeys: string[] }) => {
    selectedKeys.current = items.selectedRowKeys;
    gridRef.current.instance.option('toolbar.items[1].disabled', items.selectedRowKeys.length === 0);
    gridRef.current.instance.option('toolbar.items[2].disabled', items.selectedRowKeys.length === 0);
  };

  const onRowDblClick = (e) => {
    e.cancel = true;
    if (props.readOnly) {
      props.onUpdateItemForm([e.key]);
    }
  };

  const stateStoringOptions: any = {
    enabled: true,
    type: 'custom',
    savingTimeout: 0,
    customLoad: () => {
      const serialized = localStorage.getItem(props.storageKeyName);
      return Promise.resolve(parseJSON(serialized));
    },
    customSave: (data) => {
      const trimmed = {...data, selectedRowKeys: []};
      const serialized = JSON.stringify(trimmed);
      return Promise.resolve(localStorage.setItem(props.storageKeyName, serialized));
    },
    ignoreColumnOptionNames: []
  };

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({ component: e.component, worksheet: worksheet }).then(() => {
      workbook.xlsx.writeBuffer()
        .then((buffer) => props.onExporting(buffer));
    });
    e.cancel = true;
  };

  const getButtonText = (name: keyof IListStrings) => {
    return isMobile ? '' : props.strings[name];
  };

  const height = `calc(100vh - ${110 + (isMobile ? 40 : 0) + (isWeb ? 40 : 0)}px)`;
  const width = `100vw`;

  return (
    <DataGrid
      ref={gridRef}
      style={{ height, width, padding: 16 }}
      className='dx-theme-background-color'
      dataSource={props.items}
      allowColumnResizing={true}
      allowColumnReordering={true}
      columnResizingMode='widget'
      keyExpr='guid'
      columns={props.columns}
      onSelectionChanged={onSelectionChanged}
      onRowUpdating={onRowUpdating}
      onRowRemoving={onRowRemoving}
      onRowDblClick={onRowDblClick}
      defaultColumns={'name'}
      showBorders={true}
      stateStoring={stateStoringOptions}
      columnChooser={{ enabled: true, mode: 'select' }}
      loadPanel={{ enabled: false }}
      selection={{ mode: 'multiple' }}
      groupPanel={{ visible: !isMobile }}
      summary={{
        groupItems: [{
          column: 'name',
          summaryType: 'count',
          displayFormat: '{0}'
        }],
        totalItems: [{
          column: 'name',
          summaryType: 'count',
          displayFormat: props.strings.ItemCountTemplate
        }]
      }}
      headerFilter={{ visible: true }}
      filterRow={{ visible: true }}
      filterPanel={{ visible: true }}
      editing={{ mode: 'cell', allowUpdating: !props.readOnly, allowDeleting: !props.readOnly }}
      paging={{ enabled: false }}
      searchPanel={{ visible: !isMobile, highlightCaseSensitive: false }}
      onExporting={onExporting}
      toolbar={{
        items: [
          { name: 'groupPanel' },
          {
            widget: 'dxButton',
            location: 'after',
            disabled: true,
            options: {
              icon: 'edit',
              disabled: !props.isPlanEditEnabled,
              text: getButtonText('EditItem'),
              onClick: () => props.onUpdateItemForm(selectedKeys.current)
            }
          },
          {
            widget: 'dxButton',
            location: 'after',
            disabled: true,
            options: {
              icon: 'trash',
              disabled: !props.isPlanEditEnabled,
              text: getButtonText('DeleteItem'),
              onClick: () => props.onRemoveItemForm(selectedKeys.current)
            }
          },
          {
            name: 'exportButton'
          },
          {
            name: 'columnChooserButton'
          },
          {
            name: 'searchPanel'
          }
        ]
      }}
    >
      <Template name={'linkField'} render={props.onRenderLinkField} />
      <Export enabled={true} allowExportSelectedData={true} />
    </DataGrid>
  );
});

export default ActionListComponent;
